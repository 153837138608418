import Link from "next/link";
import dynamic from "next/dynamic";
import Markdown from "markdown-to-jsx";

import styles from "./FooterColumns.module.scss";

const FooterColumns = ({ footerData }) => {
  const columns = Object.entries(footerData)
    .filter((entry) => entry[0].includes("column"))
    .map((column) => column[1]);

  const columnElements = columns.map((column) => (
    <FooterColumn
      title={column.title}
      text={column?.text ?? null}
      links={column?.links ?? null}
      key={column.title}
    />
  ));

  return <div className={`${styles.container}`}>{columnElements}</div>;
};

export default FooterColumns;

function FooterColumn({ title, text, links }) {
  return (
    <div className={`${styles.footerColumn}`}>
      <p className={`${styles.title}`}>{title}</p>
      {text && (
        <div className={`${styles.textContainer}`}>
          <Markdown>{text}</Markdown>
        </div>
      )}
      {links && (
        <ul className={`${styles.linkList}`}>
          {links.map((link) => (
            <li
              key={link.linkName.replace(" ", "-")}
              className={`${styles.item}`}
            >
              <Link
                href={link.link}
                className={`${styles.link}`}
                target={link.target ?? "_self"}
              >
                {link.linkName}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
